import React from 'react'
import styled from 'styled-components'
import { MdAccessTime } from "react-icons/md";
import { Link } from 'react-router-dom';

const HomeLayout = () => {
  return (
    <Wrapper>
      <div className='section'>
        <h1 className='main-txt'>CUP GAMES</h1>
        <p className='select-txt'>Select what you want to play</p>
        <div className='img-container'>
          <img src={require('../assets/mainimg.png')}
            className='img'
            alt="" />
        </div>
        <div className='btn-container'>
          <Link to="/matchcups" className='btn2'>
            <button className='btn3'>Match Game</button>
          </Link>
          <Link to="fallingcup" className='btn2'>
            <button className='btn3'>Falling Cup </button>
          </Link>
          <Link to="/colormatch" className='btn2'>
            <button className='btn3'>Color Match</button>
          </Link>
          <div className='g-timers'>
            <Link to="/globaltime">
              <button className='btn-time'>
                <MdAccessTime className='icon'/>
                <span className='span-txt'>Global Time</span>
            </button>
            </Link>
          </div>
          <Link to="/rl" target="_blank" rel="noopener noreferrer">
            <p className='pv-txt rolls'>How you Play</p>
          </Link>
          <Link to="/pv" target="_blank" rel="noopener noreferrer">
            <p className='pv-txt'>PrivacyPolicy </p>
          </Link>
          <Link to="/ts" target="_blank" rel="noopener noreferrer">
            <p className='pv-txt'>Terms of Service </p>
          </Link>
          <Link to="/cp" target="_blank" rel="noopener noreferrer">
            <p className='pv-txt'>Cookie Policy</p>
          </Link>
          <div className='bottom'></div>
          </div>
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.section{
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color:  #181818;
  position: relative;
}
.main-txt{
  margin-top: 4rem;
  text-align: center;
  color: white;
}
.btn-container{
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.btn2{
    font-size: large;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 10px 50px;
    border-radius: 5px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
.btn3{
  border: none;
  background-color: transparent;
  letter-spacing: 1px;
  color: white;
  font-size: large;
  font-weight: bold;
}
.btn-time{
    margin-bottom: 1rem;
    margin-left: 1.25rem;
    font-size: large;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 10px 25px;
    /* padding: 20px 40px; */
    border-radius: 5px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
.span-txt{
  margin-left: 1rem;
}
.select-txt{
  color: white;
  text-align: center;
  letter-spacing: 7px;
}
.img-container{
  display: flex;
  justify-content: center;
}
.icon{
  font-weight: bold;
  position: absolute;
  font-size: 1.25rem;
  margin-left: -0.5rem;
}
.pv-txt{
  transition: transform 0.3s ease;
  text-align: center;
  color: white;
  margin-top: -1rem;
}
.pv-txt:hover{
  transform: scale(1.1); 
}
.bottom{
  margin-top: 2rem;
}
.rolls{
  font-weight: bold;
}
// MOBIL
@media screen and (max-width: 450px) {
.main-txt{
  margin-top: 5rem;
  font-size: 1.5rem;
}
.select-txt{
  font-size: 0.75rem;
  letter-spacing: 2px;
  margin-bottom: -3rem;
}
.btn-container{
  margin-top: -3rem;
}
.btn2{
  font-size: medium;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  padding: 7px 25px;
}
.img{
  width: 250px;
  height: 350px;
  object-fit: contain;
}
.btn-time{
  border: none;
  font-size: medium;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  padding: 7px 25px;
}
.g-timers{
  margin-top: 1rem;
  margin-right: 1rem;
}
}

`

export default HomeLayout